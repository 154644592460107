import UAParser from 'ua-parser-js';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import getPlainText from '../../utilities/getPlainText/getPlainText';
import TranslationService from '../../services/TranslationService/TranslationService';
import encodeToNumericHTMLEntities from '../../utilities/encodeToNumericHTMLEntities/encodeToNumericHTMLEntities';

dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * Represents a digital data layer, providing methods to initialize and update
 * the data layer for analytics and tracking purposes. The class supports initializing
 * with existing data, updating specific segments of the data layer, and reflecting
 * these changes globally in `window.digitalData`.
 *
 * @example - Instantiate a DataLayer and initialize it with article fetched data.
 * const dataLayer = new DataLayer();
 * await dataLayer.init(serverOptions, article);
 * @example - Instantiate a DataLayer and initialize it with existing data.
 * const dataLayer = new DataLayer(window.digitalData);
 * console.log(dataLayer.digitalData);
 */
const DataLayer = class {
    #digitalData;

    /**
     * Create a data layer.
     *
     * @param {object} [data={}] - An initial data layer object.
     */
    constructor(data = {}) {
        this.#digitalData = data;
    }

    /**
     * Initializes the data layer based on server request details, article information, and user profile.
     * @param {Object} serverOptions Options from the server-side request.
     * @param {Object} article Article data fetched previously.
     * @param {Object} userProfile A User profile.
     * @param {Object} language The article content language.
     */
    init(serverOptions = {}, article = {}, userProfile = {}, language = 'en') {
        const userAgent =
            serverOptions?.headers?.['user-agent'] ??
            window.navigator.userAgent;

        const userAgentParser = UAParser(userAgent);

        const mobile = 'Mobile';
        const desktop = 'Desktop';
        const tablet = 'Tablet';
        let deviceType = desktop;

        if (userAgentParser.device.type === 'mobile') {
            deviceType = mobile;
        } else if (userAgentParser.device.type === 'tablet') {
            deviceType = tablet;
        }

        let referringURL = '';

        if (serverOptions?.headers?.referer) {
            referringURL = serverOptions.headers.referer;
        } else if (typeof document !== 'undefined') {
            referringURL = document.referrer;
        }

        let pageURL;

        if (serverOptions?.headers) {
            pageURL = `https://${serverOptions.headers.host}${serverOptions.url}`;
        } else {
            pageURL = window.location.href;
        }

        const sysEnv = deviceType;
        const contentType = article?.issue
            ? 'Magazine Article'
            : 'Digital Article';
        const now = dayjs.utc().tz('America/New_York');
        const articlePublishDate = dayjs
            .utc(article.published)
            .format('YYYY-MM-DD');
        const articlePostingDate = dayjs
            .utc(article.published)
            .format('YYYY-MM-DD');
        const initialData = {
            pageInstanceID: `${article.title}:${process.env.NODE_ENV}`,
            siteID: 'hbrprod',
            page: {
                pageInfo: {
                    language: TranslationService.isLanguageSupported(language)
                        ? language
                        : 'en',
                    contentType,
                    pageName: article.title,
                    pageType: 'Article',
                    pageCategoryID: article.metaTags?.['page-category-id']
                        ? article.metaTags['page-category-id']
                        : null,
                    pageCategoryName: article.topics ? article.topics[0] : '', // can't leave it as undefined to avoid getServerSideProps serializing error
                    pageDescription: article.summary
                        ? getPlainText(article.summary)
                        : getPlainText(article.dek),
                    date: now.format('MM-DD-YYYY'),
                    time: now.format('hh:mm A'),
                    deviceType,
                    pageURL,
                    referringURL,
                    sysEnv,
                },
                attributes: {
                    articleEditorialDistinction: article.metaTags?.[
                        'editorial-distinction'
                    ]
                        ? article.metaTags['editorial-distinction']
                        : null,
                    articleContentType: contentType,
                    articleType: contentType,
                    articleAuthor: article.authors
                        ? article.authors.map((author) => {
                              return {
                                  authorName: encodeToNumericHTMLEntities(
                                      author.name,
                                  ),
                              };
                          })
                        : [],
                    articleID: `tag:${article.tag}`,
                    articleTitle: article.title,
                    articlePrimaryTopic: article.topics
                        ? article.topics[0]
                        : '',
                    articlePublishDate,
                    articlePostingDate,
                    primaryAuthor: article.authors
                        ? article.authors[0].name
                        : '',
                    articleSubTopics: article.topics
                        ? article.topics.map((topic) => {
                              return { subTopicName: topic };
                          })
                        : [],
                    articlePrimaryEditor: article.metaTags?.['primary-editor']
                        ? article.metaTags['primary-editor']
                        : '',
                    articleTags: ['big idea', 'spotlight'].includes(
                        article.type.toLowerCase(),
                    )
                        ? `${article.type.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())}|${contentType}`
                        : contentType,

                    configSubscriberOnly:
                        article.configurations.configSubscriberOnly,
                    configNoIntromercial:
                        article.configurations.configNoIntromercial,
                    configExemptFromPaywall:
                        article.configurations.configExemptFromPaywall,
                    editorTags: article.editorTags ? article.editorTags : [],
                    articleFindingMethod:
                        DataLayer.#determineArticleFindingMethod(
                            serverOptions,
                            referringURL,
                            pageURL,
                        ),
                    giftedArticle: article.giftedArticle,
                    noaPlayerDisplayed: article.hasAudio ? 'True' : 'False',
                },
            },

            version: '2.2',
        };

        if (Object.keys(userProfile).length > 0) {
            const profile = DataLayer.#generateUserData(userProfile);
            initialData.users = [{ profile }];
        }

        if (article.type === 'big idea' || article.type === 'spotlight') {
            initialData.page.attributes.articleEditorialDistinction =
                article.type.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                    letter.toUpperCase(),
                );
        }

        this.#digitalData = initialData;
    }

    /**
     * Use serverRequest and referringURL to determine how the user landed on the article page
     * @param {*} req
     * @param {*} referringURL
     * @param {*} pageURL
     * @returns {string|null} returns articleFindingMethod
     */
    static #determineArticleFindingMethod(req, referringURL, pageURL) {
        let articleFindingMethod = null;
        let appDomain;
        if (req?.headers) {
            appDomain = req.headers.host;
        } else {
            appDomain = new URL(window.location.href).hostname;
        }

        let documentReferrerURL;
        if (referringURL) {
            try {
                documentReferrerURL = new URL(referringURL);
            } catch (err) {
                documentReferrerURL = null;
            }
            if (documentReferrerURL) {
                const referrerDomain = documentReferrerURL.hostname;
                const referrerPath = documentReferrerURL.pathname.toLowerCase();

                if (referrerDomain === appDomain) {
                    // Internal navigation
                    if (referrerPath.startsWith('/topic/')) {
                        articleFindingMethod = 'Topic Navigation';
                    } else if (referrerPath.startsWith('/search')) {
                        articleFindingMethod = 'Search';
                    } else if (referrerPath.startsWith('/the-latest')) {
                        articleFindingMethod = 'The Latest';
                    } else if (referrerPath.startsWith('/my-library')) {
                        if (referrerPath.includes('/topics')) {
                            articleFindingMethod = 'Topic Feed';
                        } else {
                            articleFindingMethod = 'My Library';
                        }
                    } else if (referrerPath.includes('reading-list')) {
                        articleFindingMethod = 'Reading List';
                    } else if (referrerPath === '/') {
                        const queryString = new URL(pageURL).searchParams;
                        const homepageQueryValue = queryString.get('ab');
                        if (homepageQueryValue) {
                            if (
                                homepageQueryValue.includes('HP-hero-for-you')
                            ) {
                                articleFindingMethod =
                                    'Home Navigation: For You';
                            } else if (
                                homepageQueryValue.includes('HP-hero-latest')
                            ) {
                                articleFindingMethod =
                                    'Home Navigation: The Latest';
                            } else if (homepageQueryValue.includes('HP-hero')) {
                                articleFindingMethod = 'Home Navigation: Hero';
                            } else if (
                                homepageQueryValue.includes('HP-latest')
                            ) {
                                articleFindingMethod =
                                    'Home Navigation: Latest';
                            } else if (
                                homepageQueryValue.includes('HP-magazine')
                            ) {
                                articleFindingMethod =
                                    'Home Navigation: Magazine';
                            } else if (
                                homepageQueryValue.includes('HP-topics')
                            ) {
                                articleFindingMethod =
                                    'Home Navigation: Topics';
                            } else if (
                                homepageQueryValue.includes(
                                    'HP-bottom-sub-exclusive',
                                )
                            ) {
                                articleFindingMethod =
                                    'Home Navigation: HBR Subscriber Exclusive';
                            } else if (
                                homepageQueryValue.includes('HP-bottom-popular')
                            ) {
                                articleFindingMethod =
                                    'Home Navigation: Popular';
                            } else if (
                                homepageQueryValue.includes('HP-bottom-podcast')
                            ) {
                                articleFindingMethod =
                                    'Home Navigation: Podcasts';
                            } else {
                                articleFindingMethod = 'Home Navigation';
                            }
                        } else if (queryString.has('autocomplete', 'true')) {
                            articleFindingMethod = 'Directed Search';
                        }
                    } else {
                        articleFindingMethod = 'Other';
                    }
                } else {
                    articleFindingMethod = 'External';
                }
            }
        } else {
            articleFindingMethod = 'External';
        }
        return articleFindingMethod;
    }

    /**
     * Takes the user profile to generate an object to be added to the digitalData.
     * @param {object} userProfile - The user profile.
     * @returns {object} - The user info to be appended to the digitalData object.
     */
    static #generateUserData(userProfile) {
        const profile = {
            accountID: userProfile.guid?.toUpperCase(),
            dateLastVisit: userProfile.lastVisitDate.split('T')[0],
            quota: {
                limit: userProfile.quota?.limit,
                remaining: userProfile.quota?.remaining,
            },
            userState:
                userProfile.state === 'Anonymous' ? 'Guest' : userProfile.state,
            secondsSinceLastVisit: DataLayer.#getSecondsFromLastVisit(
                userProfile.lastVisitDate,
            ),
            daysSinceLastVisit: DataLayer.#getDaysFromLastVisit(
                userProfile.lastVisitDate,
            ),
            guid: userProfile.guid?.toUpperCase(),
        };
        if (userProfile.state !== 'Anonymous') {
            profile.username = userProfile.username;
            profile.attributes = {
                companySize: userProfile.companySize,
                industry: userProfile.industry,
                jobTitle: userProfile.jobTitle,
            };
            [profile.registrationDate] = userProfile.createdDate.split('T');

            if (userProfile.topics) {
                profile.feeds = [];
                const feedTopics = profile.feeds.reduce(
                    (topics, feed) => ({ ...topics, [feed.topicName]: '' }),
                    {},
                );
                const allTopics = userProfile.topics.reduce(
                    (topics, topic) => ({ ...topics, [topic.name]: '' }),
                    { ...feedTopics },
                );
                profile.feeds = Object.keys(allTopics).map((topic) => ({
                    topicName: topic,
                }));
            }

            if (userProfile.marketingCampaigns)
                profile.marketingCampaigns = userProfile.marketingCampaigns.map(
                    (campaign) => ({
                        marketingCampaignSubscribed: campaign.name,
                        marketingCampaignSubscribedId:
                            campaign.marketingCampaignId,
                    }),
                );
            if (userProfile.newsletters)
                profile.newsletters = userProfile.newsletters.map(
                    ({ name: newsletterSubscribed, ...rest }) => ({
                        newsletterSubscribed,
                        ...rest,
                    }),
                );

            if (userProfile.subscriber) {
                const { limit: giftLimit = 0, remaining: giftsRemaining = 0 } =
                    userProfile?.giftQuota || {};
                profile.giftingQuota = {
                    limit: giftLimit,
                    gift: giftLimit - giftsRemaining,
                };

                const userProfileSubscriptions =
                    userProfile.subscriber.subscriptions.slice(-1)[0];

                let subscriberData = {};

                if (userProfileSubscriptions) {
                    subscriberData = {
                        autoRenew:
                            userProfileSubscriptions.autoRenew === 1
                                ? 'T'
                                : 'F',
                        country: userProfileSubscriptions.country,
                        expirationDate:
                            userProfileSubscriptions.subscriptionAccessPeriod?.expirationDate?.split(
                                'T',
                            )[0],
                        firstPurchaseDate:
                            userProfileSubscriptions.firstPurchaseDate?.split(
                                'T',
                            )[0],
                        mostRecentPurchaseDate:
                            userProfileSubscriptions.mostRecentPurchaseDate?.split(
                                'T',
                            )[0],
                        status: userProfileSubscriptions.subscriptionStatus,
                        subscriptionAccessLevel:
                            userProfileSubscriptions.subscriptionAccessLevel,
                        subscriptionCode:
                            userProfileSubscriptions.subscriptionSourceCode,
                        subscriptionStartDate:
                            userProfileSubscriptions.subscriptionAccessPeriod?.startDate?.split(
                                'T',
                            )[0],
                        termTitle: userProfileSubscriptions.termTitle,
                        firstName: userProfile.firstName,
                    };
                }
                profile.subscriber = {};
                Object.keys(subscriberData).forEach((subscriberField) => {
                    profile.subscriber[subscriberField] =
                        subscriberData[subscriberField] !== undefined ||
                        typeof subscriberData[subscriberField] !== 'undefined'
                            ? subscriberData[subscriberField]
                            : null;
                });
            }
        }

        return profile;
    }

    /**
     * Calculates the time elapsed since the last visit in seconds.
     * @param {string} lastVisitDate - Last visit in Date String Format.
     * @returns {number} - Time elapsed in seconds since last visit.
     */
    static #getSecondsFromLastVisit(lastVisitDate) {
        const millisecsSinceLastVisit = Math.abs(
            new Date() - new Date(lastVisitDate),
        );

        return Math.round(millisecsSinceLastVisit / 1000);
    }

    /**
     * Calculates how many days have passed since last visit.
     * @param {string} lastVisitDate - Last visit in Date String Format.
     * @returns  {number | string} - Count of days since last visit, if it is less than one say returns a message.
     */
    static #getDaysFromLastVisit(lastVisitDate) {
        const SECS_IN_A_DAY = 24 * 3600;
        const secondsSinceLastVisit =
            DataLayer.#getSecondsFromLastVisit(lastVisitDate);

        let daysSinceLastVisit = Math.round(
            secondsSinceLastVisit / SECS_IN_A_DAY,
        );
        if (daysSinceLastVisit < 1) {
            daysSinceLastVisit = 'Less than one day';
        }

        return daysSinceLastVisit;
    }

    /**
     * Getter for the private member `#digitalData`.
     *
     * @returns {object} - The private member `#digitalData` which represents a data layer.
     */
    get digitalData() {
        return this.#digitalData;
    }

    /**
     * Sets an event with the given event info.
     *
     * @param {object} eventInfo - An object containing event data points.
     * @private
     */
    #setEvent(eventInfo) {
        this.#digitalData.event = [{ eventInfo }];
    }

    /**
     * Sets an event indicating a user intends to share an article.
     *
     * @param {string} contentTitle - The title of the article the user intends to share.
     * @param {string} contentType - The type of article the user intends to share: Digital or Magazine.
     * @param {string} shareMedium - The platform the user shared the article from.
     */
    setInitiatedShareEvent(contentTitle, contentType, shareMedium) {
        this.#setEvent({
            eventName: 'Content Interaction',
            eventAction: `Share via ${shareMedium}`,
            contentName: contentTitle,
            contentType,
            shareMedium,
            actionSource: 'Article Page',
        });
    }

    /**
     * Sets an event indicating a user intends to print an article.
     *
     * @param {string} contentTitle - The title of the article the user intends to print.
     * @param {string} contentType - The type of article the user intends to print: Digital or Magazine.
     */
    setPrintEvent(contentTitle, contentType) {
        this.#setEvent({
            eventName: 'Content Interaction',
            eventAction: 'Print Article',
            contentName: contentTitle,
            contentType,
        });
    }

    /**
     * Sets an event indicating a user intends to buy a copy of an article.
     *
     * @param {string} contentTitle - The title of the article the user intends to buy.
     * @param {string} contentType - The type of article the user intends to buy: Digital or Magazine.
     */
    setBuyCopiesEvent(contentTitle, contentType) {
        this.#setEvent({
            eventName: 'Content Interaction',
            eventAction: 'Buy Copies',
            contentName: contentTitle,
            contentType,
        });
    }

    /**
     * Sets an event indicating a user intends to download an article.
     *
     * @param {string} contentTitle - The title of the article the user intends to download.
     * @param {string} contentType - The type of article the user intends to download: Digital or Magazine.
     */
    setDownloadEvent(contentTitle, contentType) {
        this.#setEvent({
            eventName: 'Content Interaction',
            eventAction: 'Download PDF',
            contentName: contentTitle,
            contentType,
        });
    }

    /**
     * Sets an event indicating a user wants to view the summary of an article.
     *
     * @param {string} contentTitle - The title of the summarized article.
     * @param {string} contentType - The type of the summarized article: Digital or Magazine.
     */
    setViewSummaryEvent(contentTitle, contentType) {
        this.#setEvent({
            eventName: 'Content Interaction',
            eventAction: 'Read Summary',
            contentName: contentTitle,
            contentType,
        });
    }

    /**
     * Sets an event indicating a user subscribed to a podcast.
     *
     * @param {string} podcastSeries - The name of the podcast.
     * @param {string} media - The medium in which it is shared.
     */
    setSubscribeToPodcastEvent(podcastSeries, media) {
        this.#setEvent({
            eventAction: 'Tout Interaction',
            eventName: 'Content Interaction',
            toutName: `Podcast Promo | ${podcastSeries}`,
            toutCTAName: `Subscribe On ${media}`,
        });
    }

    /**
     * Sets an event indicating a user subscribed to a podcast.
     *
     * @param {object} newsletter - The data of the newsletter.
     */
    setSubscribeToNewsletter(newsletter) {
        let event;
        if (newsletter.configurationModel?.marketingCampaign) {
            event = {
                eventName: 'Profile Update',
                eventAction: 'Subscribe to Marketing Campaign',
                marketingCampaignName: newsletter.newsletterId,
                marketingCampaignSubClickLocation: 'Not Applicable',
            };
        } else {
            event = {
                eventName: 'Profile Update',
                eventAction: 'Subscribe to Newsletter',
                newsletterName: newsletter.newsletterId,
                newsletterSubClickLocation: newsletter.isPromo
                    ? 'Promo'
                    : `${newsletter.isPromo} | ${newsletter.orderNumber}`,
            };
        }

        this.#setEvent(event);
    }

    /**
     * Sets an event indicating a video interaction
     *
     * @param {string} action - The type of the interaction.
     * @param {string} videoName - The video that is being played.
     */
    setVideoInteraction(action, videoName) {
        this.#setEvent({
            eventName: 'Video Interaction',
            eventAction: action,
            videoName,
        });
    }

    /**
     * Sets an event indicating a subscriber intends to gift an article
     */
    setArticleGiftEvent() {
        this.#setEvent({
            eventName: 'Content Interaction',
            eventAction: 'Gift Article',
        });
    }

    /**
     * This method replaces `window.digitalData.event` with the current event
     * set in the instance.
     */
    updateEvent() {
        if (!window.digitalData) {
            window.digitalData = {};
        }

        window.digitalData.event = this.#digitalData.event || [];
    }

    updateUsers() {
        if (!window.digitalData) {
            window.digitalData = {};
        }

        window.digitalData.users = this.#digitalData.users || [];
    }
};

export default DataLayer;
