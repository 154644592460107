import HTTPError from '../../utilities/HTTPError/HTTPError';

export default class KalturaVideoService {
    static async getVideo(entryId) {
        const response = await fetch(
            'https://www.kaltura.com/api_v3/service/media/action/get?format=1',
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                credentials: 'same-origin',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify({
                    entryId: `${entryId}`,
                    version: -1,
                }),
            },
        );

        if (response.status === 200) {
            return response.json();
        }

        throw new HTTPError(response.status);
    }
}
