import { PropTypes } from 'prop-types';
import usePaywallStore from 'mfe-articles-shared/stores/paywall/paywall.store';
import PersonalizationPlacement, {
    DATA_ANALYTICS_VALUES,
} from 'mfe-articles-shared/components/PersonalizationPlacement/PersonalizationPlacement';
import useArticleStore from 'mfe-articles-renderer/src/stores/article/article.store';
import classNames from 'classnames';
import styles from './RecommendedForYou.module.scss';

const TestIds = {
    CONTAINER: 'recommended-for-you-container',
};

function RecommendedForYou({ rightRail }) {
    const isPaywallEnabled =
        usePaywallStore((state) => state.isPaywallEnabled) || rightRail;
    const { type } = useArticleStore((state) => state.article);
    const articleType = type.replace(/\s+/g, '-');

    const containerClasses = classNames(
        'content-rec-wrapper',
        styles.container,
        styles[articleType],
        {
            [styles['right-rail']]: rightRail,
        },
    );

    return (
        (rightRail || !isPaywallEnabled) && (
            <div className={containerClasses} data-testid={TestIds.CONTAINER}>
                <PersonalizationPlacement
                    name={
                        rightRail
                            ? DATA_ANALYTICS_VALUES.RECOMMENDED_FOR_YOU_RIGHT_RAIL
                            : DATA_ANALYTICS_VALUES.RECOMMENDED_FOR_YOU
                    }
                />
            </div>
        )
    );
}

RecommendedForYou.propTypes = {
    rightRail: PropTypes.bool,
};

RecommendedForYou.defaultProps = {
    rightRail: false,
};

export default RecommendedForYou;
export { TestIds as RecommendedForYouTestIds };
