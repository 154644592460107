import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import useSWR from 'swr';
import KalturaVideoService from '../../services/VideoService/KalturaVideoService';
import styles from './KalturaVideoPlayer.module.scss';
import usePaywallStore from '../../stores/paywall/paywall.store';
import DataLayer from '../../domains/DataLayer/DataLayer';

const TestIds = {
    CONTAINER: 'container',
};

function KalturaVideoPlayer({ 'video-id': videoId }) {
    const isPaywallEnabled = usePaywallStore((state) => state.isPaywallEnabled);
    const playerElementID = `kaltura_player_${videoId}`;

    const [hasAds, setHasAds] = useState(false);
    const playerRef = useRef(null);

    const PlayerEvents = {
        adstarted: 'Ad Started',
        firstplay: 'Start',
        play: 'Play',
        "playkit-ui-userclickedpause": 'Pause',
        playbackended: 'Complete',
        volumechange: 'Volume Change',
        enterfullscreen: 'Fullscreen Enter',
        exitfullscreen: 'Fullscreen Exit',
        error: 'Error',
    };

    const { data: videoData } = useSWR(videoId, KalturaVideoService.getVideo);

    const getVideoEvent = (eventName) => {
        const title = videoData?.name;
        return new CustomEvent('video-interaction', {
            detail: [title, eventName, window.parent],
        });
    };

    const triggerVideoMetrics = (metricType) => {
        return new CustomEvent(`metrics:video_${metricType}`, {
            detail: { video: videoData },
        });
    };

    const handlePlayerInteractions = (event) => {
        const dataLayer = new DataLayer(window.digitalData);
        dataLayer.setVideoInteraction(
            PlayerEvents[event.type],
            videoData?.name,
        );
        dataLayer.updateEvent();
        window.dispatchEvent(getVideoEvent(PlayerEvents[event.type]));
        if (PlayerEvents[event.type] === 'Ad Started') {
          setHasAds(true);
          document.body.dispatchEvent(
              triggerVideoMetrics('start'),
          );
        } else if (PlayerEvents[event.type] === 'Start') {
          if (!hasAds) {
            document.body.dispatchEvent(
                triggerVideoMetrics('start'),
            );
          }
        } else if (PlayerEvents[event.type] === 'Complete') {
            document.body.dispatchEvent(
                triggerVideoMetrics('complete'),
            );
        } else if (PlayerEvents[event.type] === 'Pause') {
          document.body.dispatchEvent(
            triggerVideoMetrics('pause'),
          );
        }
    };

    useEffect(() => {
        try {
            const kalturaPlayer = KalturaPlayer.setup({
                targetId: playerElementID,
                provider: {
                    partnerId: 506471,
                    uiConfId: 46057571,
                },
                playback: {
                    autoplay: false,
                },
            });

            kalturaPlayer.loadMedia({ entryId: videoId });
            playerRef.current = kalturaPlayer;
        } catch (e) {
            console.error(e.message);
        }

        if (playerRef.current) {
            playerRef.current.addEventListener(
                playerRef.current.Event.Core.AD_STARTED,
                handlePlayerInteractions,
            );
            playerRef.current.addEventListener(
                playerRef.current.Event.Core.FIRST_PLAY,
                handlePlayerInteractions,
            );
            playerRef.current.addEventListener(
                playerRef.current.Event.Core.PLAY,
                handlePlayerInteractions,
            );
            playerRef.current.addEventListener(
                playerRef.current.Event.UI.USER_CLICKED_PAUSE,
                handlePlayerInteractions,
            );
            playerRef.current.addEventListener(
                playerRef.current.Event.Core.PLAYBACK_ENDED,
                handlePlayerInteractions,
            );
            playerRef.current.addEventListener(
                playerRef.current.Event.Core.VOLUME_CHANGE,
                handlePlayerInteractions,
            );
            playerRef.current.addEventListener(
                playerRef.current.Event.Core.ENTER_FULLSCREEN,
                handlePlayerInteractions,
            );
            playerRef.current.addEventListener(
                playerRef.current.Event.Core.EXIT_FULLSCREEN,
                handlePlayerInteractions,
            );
            playerRef.current.addEventListener(
                playerRef.current.Event.Core.ERROR,
                handlePlayerInteractions,
            );

            return () => {
                playerRef.current.removeEventListener(
                    playerRef.current.Event.Core.AD_STARTED,
                    handlePlayerInteractions,
                );
                playerRef.current.removeEventListener(
                    playerRef.current.Event.Core.FIRST_PLAY,
                    handlePlayerInteractions,
                );
                playerRef.current.removeEventListener(
                    playerRef.current.Event.Core.PLAY,
                    handlePlayerInteractions,
                );
                playerRef.current.removeEventListener(
                    playerRef.current.Event.UI.USER_CLICKED_PAUSE,
                    handlePlayerInteractions,
                );
                playerRef.current.removeEventListener(
                    playerRef.current.Event.Core.PLAYBACK_ENDED,
                    handlePlayerInteractions,
                );
                playerRef.current.removeEventListener(
                    playerRef.current.Event.Core.VOLUME_CHANGE,
                    handlePlayerInteractions,
                );
                playerRef.current.removeEventListener(
                    playerRef.current.Event.Core.ENTER_FULLSCREEN,
                    handlePlayerInteractions,
                );
                playerRef.current.removeEventListener(
                    playerRef.current.Event.Core.EXIT_FULLSCREEN,
                    handlePlayerInteractions,
                );
                playerRef.current.removeEventListener(
                    playerRef.current.Event.Core.ERROR,
                    handlePlayerInteractions,
                );
            };
        }
    }, [videoData, videoId]);

    if (!isPaywallEnabled) {
        return (
            <div
                id={`kaltura_player_${videoId}`}
                className={styles['video-player']}
                data-testid={TestIds.CONTAINER}
            />
        );
    }
    return (
        <img
            src={`https://cdnsecakmi.kaltura.com/p/506471/thumbnail/entry_id/${videoId}/width/1350/`}
            role="presentation"
            className={styles.poster}
        />
    );
}

KalturaVideoPlayer.propTypes = {
    'video-id': PropTypes.string,
};

export default KalturaVideoPlayer;
export { TestIds as KalturaVideoPlayerTestIds };
