import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import usePaywallStore from 'mfe-articles-shared/stores/paywall/paywall.store';

function AssessmentHandler({ children }) {
    const { isPaywallEnabled, isPaywallLoading } = usePaywallStore((state) => ({
        isPaywallEnabled: state.isPaywallEnabled,
        isPaywallLoading: state.isPaywallLoading,
    }));

    const jquerySrc = 'https://code.jquery.com/jquery-3.7.1.min.js';
    const hasLoadedForio = useRef(false);

    useEffect(() => {
        if (isPaywallLoading || isPaywallEnabled) return;

        const loadForioScript = () => {
            if (!hasLoadedForio.current) {
                const scriptElement = document.createElement('script');
                scriptElement.innerHTML = children;
                scriptElement.setAttribute('data-testid', 'forio');
                document.body.appendChild(scriptElement);
                hasLoadedForio.current = true;
            }
        };

        const loadJqueryScript = () => {
            const scriptElement = document.createElement('script');
            scriptElement.src = jquerySrc;
            scriptElement.onload = () => {
                loadForioScript();
            };
            document.body.appendChild(scriptElement);
        };

        if (window.jQuery) loadForioScript();
        else loadJqueryScript();

        // eslint-disable-next-line consistent-return
        return () => {
            const forioScripts = document.querySelectorAll(
                '[data-testid="forio"]',
            );
            forioScripts.forEach((script) => script.remove());
        };
    }, [children, isPaywallEnabled, isPaywallLoading]);

    return null;
}

AssessmentHandler.propTypes = {
    children: PropTypes.node.isRequired,
};

export default AssessmentHandler;
